import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './PieChartComponent.css';

ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartComponentProps {
    reservations: { aprovado: boolean }[];
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ reservations }) => {
    const totalConfirmed = reservations.length ? reservations.filter(res => res.aprovado).length : 0;
    const totalPending = reservations.length - totalConfirmed;

    const data = {
        labels: ['Confirmadas', 'Pendentes'],
        datasets: [
            {
                data: [totalConfirmed, totalPending],
                backgroundColor: [
                    'rgba(70, 161, 101, 0.6)',
                    'rgba(209, 161, 38, 0.733)'
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)'
                ],
                borderWidth: 1
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                bodyColor: 'white',
                borderColor: 'gray',
                borderWidth: 1,
                bodyFont: {
                    size: 14
                },
                padding: 10
            }
        },
        maintainAspectRatio: false
    };

    return (
        <>
            <div className="chart-container">
                <Pie data={data} options={options} />
            </div>
        </>
    );
};

export default PieChartComponent;
