// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.futuristic-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    z-index: 1000;
}

.futuristic-message {
    background: linear-gradient(135deg, #1f2937, #2563eb);
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    width: 90%;
    /* Responsive width */
    max-width: 600px;
    /* Maximum width */
    text-align: center;
    animation: slide-in 0.5s ease-out forwards;
}

.futuristic-message p {
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 1rem;
}

@keyframes slide-in {
    from {
        transform: translateY(-20%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/FuturisticMessage/FuturisticMessage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,qDAAqD;IACrD,eAAe;IACf,qBAAqB;IACrB,wCAAwC;IACxC,UAAU;IACV,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,0CAA0C;AAC9C;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI;QACI,2BAA2B;QAC3B,UAAU;IACd;;IAEA;QACI,wBAAwB;QACxB,UAAU;IACd;AACJ","sourcesContent":[".futuristic-message-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* Semi-transparent background */\n    z-index: 1000;\n}\n\n.futuristic-message {\n    background: linear-gradient(135deg, #1f2937, #2563eb);\n    padding: 1.5rem;\n    border-radius: 0.5rem;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);\n    width: 90%;\n    /* Responsive width */\n    max-width: 600px;\n    /* Maximum width */\n    text-align: center;\n    animation: slide-in 0.5s ease-out forwards;\n}\n\n.futuristic-message p {\n    color: #ffffff;\n    font-size: 1rem;\n    margin-bottom: 1rem;\n}\n\n@keyframes slide-in {\n    from {\n        transform: translateY(-20%);\n        opacity: 0;\n    }\n\n    to {\n        transform: translateY(0);\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
