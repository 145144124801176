// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-container {
  padding: 1rem;
  background: linear-gradient(to right, #1f2937 0%, #4b5563 100%);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  margin-top: -0.2rem;
  width: 100%;
  overflow-x: auto;
}

.breadcrumb-list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  list-style: none;
  padding: 0;
}

.breadcrumb-item {
  margin: 0 0.5rem;
  color: #cbd5e1;
}

.breadcrumb-link {
  color: #e2e8f0;
  text-decoration: none;
  font-weight: 500;
}

.breadcrumb-link:hover {
  text-decoration: underline;
  color: #93c5fd;
}

.breadcrumb-separator {
  color: #9ca3af;
}

.breadcrumb-current {
  font-weight: bold;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/Breadcrumb.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+DAA+D;EAC/D,qBAAqB;EACrB,wCAAwC;EACxC,cAAc;EACd,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".breadcrumb-container {\n  padding: 1rem;\n  background: linear-gradient(to right, #1f2937 0%, #4b5563 100%);\n  border-radius: 0.5rem;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  margin: 1rem 0;\n  margin-top: -0.2rem;\n  width: 100%;\n  overflow-x: auto;\n}\n\n.breadcrumb-list {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: start;\n  list-style: none;\n  padding: 0;\n}\n\n.breadcrumb-item {\n  margin: 0 0.5rem;\n  color: #cbd5e1;\n}\n\n.breadcrumb-link {\n  color: #e2e8f0;\n  text-decoration: none;\n  font-weight: 500;\n}\n\n.breadcrumb-link:hover {\n  text-decoration: underline;\n  color: #93c5fd;\n}\n\n.breadcrumb-separator {\n  color: #9ca3af;\n}\n\n.breadcrumb-current {\n  font-weight: bold;\n  color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
