import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import InfoPage from './pages/InfoPage/InfoPage';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer/Footer';
import Breadcrumb from './components/Breadcrumb/Breadcrumb';
import LoginPage from './pages/LoginPage/LoginPage';
import ContactPage from './pages/ContactPage/ContactPage';
import SocialPage from './pages/SocialPage/SocialPage';
import AdminPage from './pages/AdminPage/AdminPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { fetchToken } from './store/api/api';

const App = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await fetchToken();
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao verificar o token:', error);
        setIsLoading(false);
      }
    };

    verifyToken();

    const handleResize = () => {
      setIsSidebarExpanded(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarExpanded((prev) => !prev);
  };

  if (isLoading) {
    return <></>;
  }

  const marginLeft = isSidebarExpanded ? '16rem' : '4rem';

  return (
    <Router>
      <div className="flex min-h-screen">
        <Sidebar isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
        <div className={`flex flex-col flex-grow transition-all duration-300`} style={{ marginLeft }}>
          <div className="flex-grow p-4">
            <Breadcrumb />
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/info" component={InfoPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/social" component={SocialPage} />
              <Route path="/login" component={LoginPage} />
              <PrivateRoute path="/admin" component={AdminPage} />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
