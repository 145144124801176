import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';
import ReservationModal from '../Modals/ReservationModal/ReservationModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import moment from 'moment';
import FuturisticMessage from '../FuturisticMessage/FuturisticMessage';
import loadingAnimation from '../../assets/animations/loading.json';
import Lottie from 'react-lottie';
import { clearMessage, setMessage } from '../../store/states/message/actions';
import { fetchReservaTodasRequest } from '../../store/states/reserva/actions';
registerLocale('pt-BR', ptBR);

interface CalendarComponentProps {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({ selectedDate, onChange }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reservations = useSelector((state: RootState) => state.reserva.data);
  const loadingReservations = useSelector((state: RootState) => state.reserva.loading);
  const [reservas, setReservas] = React.useState<any[]>([]);

  useEffect(() => {
    setReservas(reservations);
  }, [dispatch, reservations]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
    speed: 2
  };

  const approvedDates = reservas.length ? reservas.filter((reservation: any) => reservation.aprovado).map((reserva: any) =>
    new Date(moment(reserva.dataSelecionada + "T00:00:00").format("YYYY-MM-DDTHH:mm:ss"))
  ) : [];

  const handleDateClick = (date: Date | null) => {
    const dateString = moment(date).format("YYYY-MM-DD");
    if (date && approvedDates.some((d: any) => moment(d).format("YYYY-MM-DD") === dateString)) {
      onChange(null);
      dispatch(setMessage("Esta data já está reservada! Reserve outro dia :)"));
    } else {
      onChange(date);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    dispatch(fetchReservaTodasRequest());
    setIsModalOpen(false);
    onChange(null);
  };

  return (
    <>
      {loadingReservations ?
        <>
          <Lottie options={defaultOptions} height={150} width={150} />
        </> :
        <>
          <div className="calendar-wrapper">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateClick}
              inline
              locale="pt-BR"
              minDate={new Date()}
              highlightDates={approvedDates}
              className="text-center"
            />
            <ReservationModal
              isOpen={isModalOpen}
              onClose={closeModal}
              selectedDate={selectedDate}
            />
            <FuturisticMessage onClose={() => dispatch(clearMessage())} />
          </div>
        </>}
    </>
  );
};

export default CalendarComponent;
