import { Reducer } from 'redux';
import { SET_MESSAGE, CLEAR_MESSAGE } from './types';

const initialState = {
  message: ''
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MESSAGE:
      return { ...state, message: action.payload };
    case CLEAR_MESSAGE:
      return { ...state, message: '' };
    default:
      return state;
  }
};
