// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px auto;
    background-color: linear-gradient(145deg, #354b6b, #1f2937);
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    cursor: pointer;
    color: #ffffff;
}

.chart-container h2 {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 20px;
}

.chart-total{
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
}

@media (max-width: 768px) {
    .chart-container {
        padding: 10px;
        margin-top: 10px;
        max-width: 100%;
        max-width: 300px;
    }

    .chart-container h2 {
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/PieChartComponent/PieChartComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,2DAA2D;IAC3D,mBAAmB;IACnB,wCAAwC;IACxC,WAAW;IACX,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;QACb,gBAAgB;QAChB,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".chart-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    margin: 20px auto;\n    background-color: linear-gradient(145deg, #354b6b, #1f2937);\n    border-radius: 10px;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    cursor: pointer;\n    color: #ffffff;\n}\n\n.chart-container h2 {\n    color: #ffffff;\n    font-size: 16px;\n    margin-bottom: 20px;\n}\n\n.chart-total{\n    font-size: 24px;\n    font-weight: bold;\n    color: #ffffff;\n}\n\n@media (max-width: 768px) {\n    .chart-container {\n        padding: 10px;\n        margin-top: 10px;\n        max-width: 100%;\n        max-width: 300px;\n    }\n\n    .chart-container h2 {\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
