import React, { useEffect, useState } from 'react';
import './FuturisticMessage.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { clearMessage } from '../../store/states/message/actions';

interface FuturisticMessageProps {
    onClose: () => void;
}

const FuturisticMessage: React.FC<FuturisticMessageProps> = ({ onClose }) => {
    const dispatch = useDispatch();
    const [showMessage, setShowMessage] = useState(false);
    const messageState = useSelector((state: RootState) => state.message.message);
    useEffect(() => {
        if (messageState) {
            setShowMessage(true);
            setTimeout(() => dispatch(clearMessage()), 5000);
        } else {
            setShowMessage(false);
        }
    }, [messageState]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose, showMessage]);
    if (!showMessage) return null;
    return (
        <div className="futuristic-message-overlay animate-slide-in">
            <div className="futuristic-message">
                <p>{messageState}</p>
                <button onClick={onClose} className="futuristic-button">Fechar</button>
            </div>
        </div>
    );
};

export default FuturisticMessage;
