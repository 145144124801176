// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-modal {
    color: #ffffff;
}

.confirm-modal-content {
    padding: 20px;
    text-align: center;
  }
  
  .confirm-modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .confirm-reserve-button, .cancel-reserve-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-reserve-button {
    background-color: #4CAF50;
    color: white;
    min-width: 200px;
  }
  
  .confirm-reserve-button:hover {
    background-color: #45a049;
  }
  
  .cancel-reserve-button {
    background-color: #f44336;
    color: white;
    min-width: 200px;
  }
  
  .cancel-reserve-button:hover {
    background-color: #d32f2f;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Modals/ConfirmModal/ConfirmModal.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".message-modal {\n    color: #ffffff;\n}\n\n.confirm-modal-content {\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .confirm-modal-actions {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-around;\n  }\n  \n  .confirm-reserve-button, .cancel-reserve-button {\n    padding: 10px 20px;\n    margin: 0 10px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .confirm-reserve-button {\n    background-color: #4CAF50;\n    color: white;\n    min-width: 200px;\n  }\n  \n  .confirm-reserve-button:hover {\n    background-color: #45a049;\n  }\n  \n  .cancel-reserve-button {\n    background-color: #f44336;\n    color: white;\n    min-width: 200px;\n  }\n  \n  .cancel-reserve-button:hover {\n    background-color: #d32f2f;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
