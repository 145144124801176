// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-input {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: none;
    width: 100%;
    max-width: 320px;
    color: #1f2937;
}

.restrict-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #cbd5e1;
    margin-bottom: 1rem;
}

.title-login {
    color: #cbd5e1;
    margin-bottom: 1rem;
}

.admin-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    margin-top: -20rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

h1 {
    color: #cbd5e1;
    margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;;IAEA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".login-panel {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.login-input {\n    padding: 1rem;\n    margin-bottom: 1rem;\n    border-radius: 0.5rem;\n    border: none;\n    width: 100%;\n    max-width: 320px;\n    color: #1f2937;\n}\n\n.restrict-title {\n    font-size: 2.5rem;\n    font-weight: bold;\n    color: #cbd5e1;\n    margin-bottom: 1rem;\n}\n\n.title-login {\n    color: #cbd5e1;\n    margin-bottom: 1rem;\n}\n\n.admin-panel {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    opacity: 0;\n    animation: fadeIn 1s ease-in-out forwards;\n    margin-top: -20rem;\n}\n\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n        transform: translateY(20px);\n    }\n\n    to {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n\nh1 {\n    color: #cbd5e1;\n    margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
