import React, { useState } from 'react';
import Modal from 'react-modal';
import './EmailModal.css';

interface EmailModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const EmailModal: React.FC<EmailModalProps> = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        mensagem: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(formData);
        alert('Mensagem enviada com sucesso!');
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Envie-nos uma mensagem"
            className="modal-content"
            overlayClassName="modal-overlay"
        >
            <h2 className="modal-title">Envie-nos uma Mensagem</h2>
            <form onSubmit={handleSubmit} className="modal-form">
                <input
                    type="text"
                    name="nome"
                    placeholder="Seu Nome"
                    value={formData.nome}
                    onChange={handleChange}
                    className="modal-input-field"
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Seu Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="modal-input-field"
                />
                <textarea
                    name="mensagem"
                    placeholder="Sua Mensagem"
                    value={formData.mensagem}
                    onChange={handleChange}
                    className="modal-input-field textarea-field"
                />
                <button type="submit" className="modal-submit-button">Enviar</button>
                <button type="button" onClick={onClose} className="modal-cancel-button">Cancelar</button>
            </form>
        </Modal>
    );
};

export default EmailModal;
