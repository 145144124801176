// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background-color: #F5F5F5;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.filter-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 10px;
}

.input-checkbox {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.date-picker-container {
    display: flex;
    gap: 10px;
}

@media (max-width: 768px) {

    .filters,
    .pagination {
        flex-direction: column;
        align-items: center;
    }

    .filters {
        padding: 10px;
    }

    .filter-label {
        font-size: 14px;
    }

    .search-input,
    .react-datepicker__input-container input {
        margin-bottom: 10px;
    }

    .date-picker-container {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/FilterComponent/FilterComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,yBAAyB;IACzB,qBAAqB;IACrB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;;IAEI;;QAEI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;;QAEI,mBAAmB;IACvB;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".filters {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 15px;\n    background-color: #F5F5F5;\n    border-radius: 0.5rem;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.filter-group {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.filter-label {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    margin-right: 10px;\n}\n\n.input-checkbox {\n    width: 24px;\n    height: 24px;\n    margin-right: 5px;\n}\n\n.date-picker-container {\n    display: flex;\n    gap: 10px;\n}\n\n@media (max-width: 768px) {\n\n    .filters,\n    .pagination {\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .filters {\n        padding: 10px;\n    }\n\n    .filter-label {\n        font-size: 14px;\n    }\n\n    .search-input,\n    .react-datepicker__input-container input {\n        margin-bottom: 10px;\n    }\n\n    .date-picker-container {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
