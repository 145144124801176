import React from 'react';
import Modal from 'react-modal';
import './ConfirmModal.css';

interface ConfirmModalProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onConfirm, onCancel, message }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onCancel} contentLabel="Confirmação" className="confirm-modal" overlayClassName="modal-overlay">
            <div className="confirm-modal-content">
                <p className='message-modal'>{message}</p>
                <div className="confirm-modal-actions">
                    <button onClick={onConfirm} className="confirm-reserve-button">Confirmar</button>
                    <button onClick={onCancel} className="cancel-reserve-button">Não</button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
