import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Breadcrumb.css';

const Breadcrumb: React.FC = () => {
  const location = useLocation();
  const routes: { [key: string]: string } = {
    '/': 'Home',
    '/info': 'Informações',
    '/contact': 'Contato',
    '/social': 'Social Maljj',
    '/login': 'Área restrita',
    '/admin': 'Painel Admin',
  };

  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <nav className="breadcrumb-container">
      <ol className="breadcrumb-list">
        <li>
          <Link to="/" className="breadcrumb-link">
            Home
          </Link>
        </li>
        {pathnames.map((_, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return (
            <li key={to} className="breadcrumb-item">
              <span className="breadcrumb-separator">/</span>
              {index === pathnames.length - 1 ? (
                <span className="breadcrumb-current"> {routes[to] || 'Página'}</span>
              ) : (
                <Link to={to} className="breadcrumb-link">
                  {routes[to] || 'Página'}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
