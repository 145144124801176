import React, { useEffect, useState } from 'react';
import './InfoPage.css';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import informationAnimation from '../../assets/animations/information.json';
import useTypewriter from 'react-typewriter-hook';
import { FiCodepen, FiSun, FiInfo, FiDownloadCloud } from "react-icons/fi";


const InfoPage: React.FC = () => {
  const magicTexts = [
    "O Espaço foi projetado para proporcionar excelência ao seu evento e está localizado no centro comercial do bairro Botafogo na cidade de RIBEIRÃO DAS NEVES/MG.",
    "Oferecemos uma estrutura moderna, materiais de qualidade e uma área verde com lindo pergolado, capaz de proporcionar descanso e bem-estar para você e seus convidados!",
    "Faça seu evento com a gente!"
  ];
  const [index, setIndex] = useState(0);
  let typewriterText = useTypewriter(magicTexts[index % magicTexts.length]);
  const managementOptions = {
    loop: true,
    autoplay: true,
    animationData: informationAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const currentText = magicTexts[index % magicTexts.length];
    const timeoutLength = currentText.length * 50;
    const timer = setTimeout(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, timeoutLength);
    return () => clearTimeout(timer);
  }, [index, magicTexts]);
  return (
    <div className="futuristic-container animate-slide-in">
      <h1 className="futuristic-title">Bem-vindo ao Espaço Maljj</h1>
      <Lottie options={managementOptions} height={150} width={150} />
      <p className="futuristic-subtitle">{typewriterText}</p>
      <div className="informacoes-grid">
        <div className="informacoes-card">
          <FiCodepen /><h2 className="card-title">Estrutura</h2>
          <ul className="card-content">
            <li>Piscina aquecida</li>
            <li>Playground 3 andares (Brinquedão Kidplay)</li>
            <li>25 jogos de mesas</li>
            <li>Som, TV e Youtube Premium</li>
            <li>Bebedouro (Água gelada)</li>
            <li>Cervejeira 537L</li>
            <li>Fogão industrial 4 bocas (Não inclui gás)</li>
            <li>Churrasqueira 2 grelhas</li>
            <li>Wi-Fi</li>
            <li>Pergolado de madeira</li>
            <li>Espaço 360m²</li>
            <li>Banheiro Feminino, Masculino e Acessibilidade (Cadeirante)</li>
            <li>Alvará de funcionamento</li>
            <li>Alvará do corpo de bombeiros AVCB (Com placas de sinalização e extintores)</li>
          </ul>
        </div>
        <div className="informacoes-card">
          <FiSun /><h2 className="card-title">Ajuda - Funcionamento</h2>
          <ul className="card-content">
            <li>Horário: 12H de uso - 11:00 as 23:00H</li>
            <li>Capacidade: 100 pessoas</li>
            <li>
              <button type="button" onClick={() => window.open('./contrato_locacao.docx', '_blank')} className="download-info-button">
                <FiDownloadCloud className="download-info-icon" />Baixar Contrato de Locação
              </button>
            </li>
          </ul>
        </div>
        <div className="informacoes-card">
          <FiInfo /><h2 className="card-title">Observações</h2>
          <ul className="card-content">
            <li>Não cobramos taxa de limpeza</li>
            <li>Taxa para utilização do nosso gás: R$ 40,00</li>
            <li>Aceitamos cartão de crédito, débito e PIX.</li>
          </ul>
        </div>
      </div>
      <Link to="/">
        <button className="futuristic-button">Reservar Agora</button>
      </Link>
    </div>
  );
};

export default InfoPage;
