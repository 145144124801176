import { useState } from 'react';
import Lottie from 'react-lottie';
import adminAnimation from '../../assets/animations/admin.json';
import './LoginPage.css';
import { useDispatch } from 'react-redux';
import { login } from '../../store/states/auth/actions';
import { useHistory } from 'react-router-dom';

const LoginPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const managementOptions = {
        loop: true,
        autoplay: true,
        animationData: adminAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleLogin = () => {
        dispatch(login(email, password, history));
    };

    return (
        <div className="futuristic-container animate-slide-in">
            <h1 className="futuristic-title">Área de Login</h1>
            <Lottie options={managementOptions} height={150} width={150} />
            <div className="login-panel">
                <p className='title-login'>Insira suas credenciais para acessar o Painel de Administração:</p>
                <input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="login-input"
                />
                <input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-input"
                />
            </div>
            <button onClick={handleLogin} className="futuristic-button">Entrar</button>
        </div>
    );
};

export default LoginPage;
