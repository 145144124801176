// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.total-reservations,
.total-confirmed,
.total-pending {
    text-align: center;
    font-size: 16px;
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.export-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.export-button:hover {
    background-color: #367C2B;
}


@media (max-width: 768px) {
    .statistics {
        flex-direction: column;
        align-items: center;
    }

    .total-reservations,
    .total-confirmed,
    .total-pending {
        margin-bottom: 10px;
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminPage/AdminPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;;;IAGI,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;;;QAGI,mBAAmB;QACnB,UAAU;IACd;AACJ","sourcesContent":[".statistics {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 20px;\n}\n\n.total-reservations,\n.total-confirmed,\n.total-pending {\n    text-align: center;\n    font-size: 16px;\n    padding: 10px;\n    background-color: #f8f9fa;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n}\n\n.export-button {\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    margin: 10px;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.export-button:hover {\n    background-color: #367C2B;\n}\n\n\n@media (max-width: 768px) {\n    .statistics {\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .total-reservations,\n    .total-confirmed,\n    .total-pending {\n        margin-bottom: 10px;\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
