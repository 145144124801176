// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #1f2937;
    color: #ffffff;
    padding: 1rem;
    text-align: center;
}

footer a {
    color: inherit;
    text-decoration: none;
}

footer a:hover {
    color: #3b82f6;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["footer {\n    background-color: #1f2937;\n    color: #ffffff;\n    padding: 1rem;\n    text-align: center;\n}\n\nfooter a {\n    color: inherit;\n    text-decoration: none;\n}\n\nfooter a:hover {\n    color: #3b82f6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
