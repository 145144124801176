// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonials {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 2rem;
}

.testimonial-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    color: #1f2937;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.testimonial-stars {
    display: flex;
    margin-bottom: 10px;
}

.testimonial-text {
    font-size: 0.9rem;
    text-align: center;
    margin-top: 10px;
}

.testimonial-author {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 5px;
    color: #2563eb;
}

@media (max-width: 768px) {
    .testimonials {
        grid-template-columns: 1fr;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/SocialPage/SocialPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,wCAAwC;IACxC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,qDAAqD;AACzD;;AAEA;IACI,2BAA2B;IAC3B,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".testimonials {\n    width: 100%;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n    margin-top: 2rem;\n}\n\n.testimonial-card {\n    background: #ffffff;\n    padding: 20px;\n    border-radius: 10px;\n    color: #1f2937;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.testimonial-card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);\n}\n\n.testimonial-stars {\n    display: flex;\n    margin-bottom: 10px;\n}\n\n.testimonial-text {\n    font-size: 0.9rem;\n    text-align: center;\n    margin-top: 10px;\n}\n\n.testimonial-author {\n    font-size: 1rem;\n    font-weight: bold;\n    margin-top: 5px;\n    color: #2563eb;\n}\n\n@media (max-width: 768px) {\n    .testimonials {\n        grid-template-columns: 1fr;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
