import React, { useEffect, useState } from 'react';
import './StatsPanel.css';

interface Reservation {
    aprovado: boolean;
}

interface StatsPanelProps {
    reservations: Reservation[];
}

export const StatsPanel: React.FC<StatsPanelProps> = ({ reservations }) => {
    const [animateCounter, setAnimateCounter] = useState(false);

    useEffect(() => {
        setAnimateCounter(true);
    }, []);

    const totalReservations = reservations.length;
    const totalConfirmed = reservations.length ? reservations.filter(r => r.aprovado).length : [];
    const totalPending = totalReservations - (totalConfirmed as number);

    return (
        <div className="statistics">
            <div className="total-reservations">Total Reservas: <span className={animateCounter ? 'counter' : ''}>{totalReservations}</span></div>
            <div className="total-confirmed">Reserva(s) Confirmada(s): <span className={animateCounter ? 'counter' : ''}>{totalConfirmed}</span></div>
            <div className="total-pending">Reserva(s) Pendente(s): <span className={animateCounter ? 'counter' : ''}>{totalPending}</span></div>
        </div>
    );
};