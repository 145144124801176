import { Reducer } from 'redux';
import {
  FETCH_RESERVA_TODAS_SUCCESS,
  FETCH_RESERVA_TODAS_FAILURE,
  CREATE_RESERVA_REQUEST,
  CREATE_RESERVA_SUCCESS,
  CREATE_RESERVA_FAILURE,
  FETCH_RESERVA_TODAS_REQUEST,
  APPROVE_RESERVA_REQUEST,
  APPROVE_RESERVA_SUCCESS,
  APPROVE_RESERVA_FAILURE,
  CANCEL_RESERVA_SUCCESS,
  CANCEL_RESERVA_REQUEST,
  CANCEL_RESERVA_FAILURE
} from './types';

const initialState = {
  data: [],
  loading: false,
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_RESERVA_TODAS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RESERVA_TODAS_SUCCESS:
      return {
        ...state,
        data: action.data.slice(),
        loading: false,
      };
    case FETCH_RESERVA_TODAS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case CREATE_RESERVA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_RESERVA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_RESERVA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case APPROVE_RESERVA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case APPROVE_RESERVA_SUCCESS:
      const newData = state.data.map((reserva: any) =>
        reserva.reservaId === action.payload ? { ...reserva, aprovado: true } : reserva
      );
      return {
        ...state,
        data: newData,
        loading: false,
      };
    case APPROVE_RESERVA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CANCEL_RESERVA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_RESERVA_SUCCESS:
      const updatedData = state.data.map((reserva: any) =>
        reserva.reservaId === action.payload ? { ...reserva, aprovado: false } : reserva
      );
      return {
        ...state,
        data: updatedData,
      };
    case CANCEL_RESERVA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
