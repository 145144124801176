import api from '../api';

export interface Reserva {
    reservaId: string;
    dataSelecionada: string;
    documento: string;
    nome: string;
    telefone: string;
    endereco: string;
    aprovado: boolean;
    dataCadastro: string;
}

export async function reservaTodasApi(): Promise<Reserva[]> {
    try {
        const response = await api.get<Reserva[]>('/smarf-schedule/reservas/todas');
        return response.data;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function createReservaApi(reservaData: any): Promise<any> {
    try {
        const response = await api.post('/smarf-schedule/reservas/nova-reserva', reservaData);
        return response.data;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function approveReservaApi(reservaId: string): Promise<any> {
    try {
        const response = await api.put(`/smarf-schedule/reservas/aprovar-reserva/${reservaId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function cancelReservaApi(reservaId: string): Promise<any> {
    try {
        const response = await api.put(`/smarf-schedule/reservas/cancelar-reserva/${reservaId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error.message);
    }
}
