import api from '../api';

export interface LoginResponse {
    access_token: string;
}

export async function authApi(email: string, password: string): Promise<LoginResponse> {
    const response = await api.post<LoginResponse>('/login', {
        email,
        password
    });
    return response.data;
}
