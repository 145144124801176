// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    max-width: 1000px;
    margin: auto;
    padding: 2rem;
    background-color: #f3f4f6;
    color: #1f2937;
    text-align: center;
}

.contact-description {
    font-size: 1.25rem;
    color: #cbd5e1;
    margin-bottom: 2rem;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
}

.contact-animation {
    margin: 1rem auto 2rem;
}

.contact-info {
    margin-top: 2rem;
    font-size: 1rem;
    color: #cbd5e1;
    text-align: left;
}

.contact-info h2 {
    font-size: 1.2rem;
    color: #cbd5e1;
    margin-bottom: 1rem;
    text-align: center;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0.5rem 0;
}

.contact-icon {
    color: #cbd5e1;
    font-size: 1.25rem;
}

.map-container {
    margin-top: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .contact-title {
        font-size: 2rem;
    }

    .contact-description {
        font-size: 1rem;
        padding: 0 1rem;
    }

    .open-modal-button {
        width: 90%;
    }

    .contact-info {
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/ContactPage/ContactPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,0CAA0C;AAC9C;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,eAAe;IACnB;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".contact-container {\n    max-width: 1000px;\n    margin: auto;\n    padding: 2rem;\n    background-color: #f3f4f6;\n    color: #1f2937;\n    text-align: center;\n}\n\n.contact-description {\n    font-size: 1.25rem;\n    color: #cbd5e1;\n    margin-bottom: 2rem;\n    line-height: 1.6;\n    max-width: 800px;\n    margin: auto;\n}\n\n.contact-animation {\n    margin: 1rem auto 2rem;\n}\n\n.contact-info {\n    margin-top: 2rem;\n    font-size: 1rem;\n    color: #cbd5e1;\n    text-align: left;\n}\n\n.contact-info h2 {\n    font-size: 1.2rem;\n    color: #cbd5e1;\n    margin-bottom: 1rem;\n    text-align: center;\n}\n\n.contact-item {\n    display: flex;\n    align-items: center;\n    gap: 0.75rem;\n    margin: 0.5rem 0;\n}\n\n.contact-icon {\n    color: #cbd5e1;\n    font-size: 1.25rem;\n}\n\n.map-container {\n    margin-top: 2rem;\n    border-radius: 1rem;\n    overflow: hidden;\n    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);\n}\n\n@media (max-width: 768px) {\n    .contact-title {\n        font-size: 2rem;\n    }\n\n    .contact-description {\n        font-size: 1rem;\n        padding: 0 1rem;\n    }\n\n    .open-modal-button {\n        width: 90%;\n    }\n\n    .contact-info {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
