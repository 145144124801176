import React from 'react';
import './SocialPage.css';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import feedbackAnimation from '../../assets/animations/feedback.json';
import starAnimation from '../../assets/animations/feedback.json';

const SocialPage: React.FC = () => {
  const feedbackOptions = {
    loop: true,
    autoplay: true,
    animationData: feedbackAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const testimonials = [
    {
      id: 1,
      text: "O Espaço Maljj proporcionou o ambiente perfeito para nossa reunião de família. Todos ficaram encantados com o local e os serviços.",
      author: "Ana Júlia",
      stars: 5
    },
    {
      id: 2,
      text: "Realizamos nosso evento corporativo no Espaço Maljj e foi um sucesso total. O suporte e as instalações são de primeira linha.",
      author: "Carlos Silva",
      stars: 5
    },
    {
      id: 3,
      text: "Minha festa de aniversário foi inesquecível graças à equipe do Espaço Maljj. O ambiente é incrível e muito bem cuidado!",
      author: "Mariana Costa",
      stars: 5
    }
  ];

  const renderStars = (count: any) => {
    return Array.from({ length: count }, (_, i) => (
      <Lottie key={i} options={{
        loop: false,
        autoplay: true,
        animationData: starAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet'
        }
      }} height={20} width={20} />
    ));
  };

  return (
    <div className="futuristic-container animate-slide-in">
      <h1 className="futuristic-title">Veja o que outros clientes estão achando!</h1>
      <Lottie options={feedbackOptions} height={150} width={150} />
      <p className="futuristic-subtitle">
        Agradecemos por escolher o Espaço Maljj para seu evento! Compartilhe sua experiência para ajudar a melhorar nosso serviço.
      </p>
      <button className="futuristic-button">Enviar Relato</button>
      <div className="testimonials">
        {testimonials.map(testimonial => (
          <div key={testimonial.id} className="testimonial-card">
            <div className="testimonial-stars">{renderStars(testimonial.stars)}</div>
            <p className="testimonial-text">{testimonial.text}</p>
            <p className="testimonial-author">{testimonial.author}</p>
          </div>
        ))}
      </div>
      <Link to="/">
        <button className="futuristic-button">Voltar à Página Inicial</button>
      </Link>
    </div>
  );
};

export default SocialPage;
