import { Reducer } from 'redux';
import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT, SET_ACCESS_TOKEN } from './types';

const initialState = {
  accessToken: undefined,
  user: undefined,
  loading: false,
};

export const reducer: Reducer<any, any> = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        accessToken: undefined,
        user: undefined,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        user: action.payload.user,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        accessToken: undefined,
        user: undefined,
        loading: false,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        user: action.payload.user,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        accessToken: undefined,
        user: undefined,
        loading: false,
      };
    default:
      return state;
  }
};
