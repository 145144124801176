import React, { useState } from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import './ReservationModal.css';
import reserveAnimation from '../../../assets/animations/calendar-reserve.json';
import Lottie from 'react-lottie';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { createReservaRequest, fetchReservaTodasRequest } from '../../../store/states/reserva/actions';
import { setMessage } from '../../../store/states/message/actions';

interface ReservationModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedDate: Date | null;
}

const reservationSchema = Yup.object().shape({
    nome: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    documento: Yup.string().required('CPF é obrigatório').matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
    telefone: Yup.string().required('Telefone é obrigatório').matches(/\(\d{2}\) \d \d{4}-\d{4}/, 'Telefone inválido'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    metodoPagamento: Yup.string().required('Método de pagamento é obrigatório'),
    termsAccepted: Yup.boolean().oneOf([true], 'Você deve aceitar os termos para continuar.')
});

const ReservationModal: React.FC<ReservationModalProps> = ({ isOpen, onClose, selectedDate }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: reserveAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const submitReservation = (formData: any) => {
        const apiData = {
            reserva: {
                dataSelecionada: moment(selectedDate).format('YYYY-MM-DD'),
                nome: formData.nome,
                documento: formData.documento,
                telefone: formData.telefone,
                endereco: formData.endereco,
                aprovado: false,
            },
            pagamento: {
                valorEntrada: 0,
                valorRecebido: 0,
                valorRestante: 0,
                dataPagamento: null,
                metodoPagamento: formData.metodoPagamento,
                status: false,
            }
        };
        dispatch(createReservaRequest(apiData));
        dispatch(setMessage("Pré-reserva realizada com sucesso! Logo entraremos em contato para confirmar os detalhes :)"));
        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={() => { onClose(); setStep(1); }} contentLabel="Reservar o dia" className="modal-content" overlayClassName="modal-overlay">
                <p className='selected-date'>Reservar dia {selectedDate ? moment(selectedDate).format('DD/MM/YYYY') : 'Nenhuma data selecionada'}</p>
                <Lottie options={defaultOptions} height={75} width={75} />
                <Formik
                    initialValues={{
                        nome: '',
                        email: '',
                        documento: '',
                        telefone: '',
                        endereco: '',
                        metodoPagamento: 'Pix',
                        valorEntrada: 0.00,
                        valorRecebido: 0.00,
                        valorRestante: 0.00,
                        termsAccepted: false,
                    }}
                    validationSchema={reservationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        if (step === 1) {
                            setStep(2);
                        } else {
                            submitReservation(values);
                            setStep(1);
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ errors, touched, values }) => (
                        <Form className="modal-form">
                            {step === 1 ? (
                                <>
                                    <Field name="nome" type="text" placeholder="Nome completo" className="input-field" />
                                    {errors.nome && touched.nome ? <div className="error">{errors.nome}</div> : null}

                                    <Field name="email" type="email" placeholder="Email" className="input-field" />
                                    {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}

                                    <Field name="documento" as={InputMask} mask="999.999.999-99" placeholder="CPF" className="input-field" />
                                    {errors.documento && touched.documento ? <div className="error">{errors.documento}</div> : null}

                                    <Field name="telefone" as={InputMask} mask="(99) 9 9999-9999" placeholder="Telefone" className="input-field" />
                                    {errors.telefone && touched.telefone ? <div className="error">{errors.telefone}</div> : null}

                                    <Field name="endereco" type="text" placeholder="Endereço completo" className="input-field" />
                                    {errors.endereco && touched.endereco ? <div className="error">{errors.endereco}</div> : null}

                                    <button type="button" onClick={() => setStep(2)} className="submit-button">Avançar</button>
                                </>
                            ) : (
                                <>
                                    <div className="payment-details">
                                        <h2>Confirmação de Pagamento</h2>
                                        <p>
                                            Clique abaixo para copiar o PIX e realizar o pagamento da metade (50%) do valor total da locação.<br />
                                            <strong>Chave PIX: 46.941.905/0001-67</strong>
                                        </p>
                                        <p>Ao realizar o pagamento, favor enviar o comprovante para o(s) número(s):<br />
                                            <strong>(31) 99813-5312 ou (31) 99494-8900</strong></p>
                                        <p><em>IMPORTANTE: A reserva só será concluída mediante a confirmação de pagamento.</em></p>
                                        <p><em>Proibido locação do espaço para eventos remunerados.</em></p>
                                        <p><em>Respeite o espaço em geral, e se atente aos horários de contratação para não sofrer encargos.</em></p>
                                        <p><em>Não é permitido ultrapassar o máximo de pessoas acordadas na negociação.</em></p>
                                        <Field type="checkbox" name="termsAccepted" className="terms-checkbox" />
                                        <label htmlFor="termsAccepted">Eu li e concordo com os termos e condições.</label>
                                        {errors.termsAccepted && touched.termsAccepted ? <div className="error">{errors.termsAccepted}</div> : null}
                                        <Field name="metodoPagamento" as="select" className="input-field">
                                            <option value="Pix">Pix</option>
                                            <option value="Cartão de Crédito">Cartão de Crédito</option>
                                            <option value="Boleto">Boleto</option>
                                        </Field>
                                        <button type="button" className="download-contract-button" onClick={() => window.open('./contrato_locacao.docx', '_blank')}>
                                            Baixar Contrato
                                        </button>
                                        <div className="button-group">
                                            <button type="button" onClick={() => setStep(1)} className="cancel-button">Voltar</button>
                                            <button type="submit" className="submit-button" disabled={!values.termsAccepted}>Confirmar</button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default ReservationModal;
