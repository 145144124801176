// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    background-color: #1f2937;
}

.sidebar-logo {
    margin-left: -0.5rem;
}

.sidebar-expanded {
    width: 16rem;
}

.sidebar-collapsed {
    width: 4rem;
}

.sidebar a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1.3rem;
    color: #e5e7eb;
    transition: all 0.3s ease-in-out;
    border-radius: 0.5rem;
}

.sidebar a:hover {
    background-color: #4b5563;
    color: #f3f4f6;
    transform: scale(1.05);
}

.sidebar button {
    transition: transform 0.3s ease-in-out;
}

.sidebar button:hover {
    transform: rotate(90deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,uBAAuB;IACvB,cAAc;IACd,gCAAgC;IAChC,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".sidebar {\n    background-color: #1f2937;\n}\n\n.sidebar-logo {\n    margin-left: -0.5rem;\n}\n\n.sidebar-expanded {\n    width: 16rem;\n}\n\n.sidebar-collapsed {\n    width: 4rem;\n}\n\n.sidebar a {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    padding: 0.75rem 1.3rem;\n    color: #e5e7eb;\n    transition: all 0.3s ease-in-out;\n    border-radius: 0.5rem;\n}\n\n.sidebar a:hover {\n    background-color: #4b5563;\n    color: #f3f4f6;\n    transform: scale(1.05);\n}\n\n.sidebar button {\n    transition: transform 0.3s ease-in-out;\n}\n\n.sidebar button:hover {\n    transform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
