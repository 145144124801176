export const FETCH_RESERVA_TODAS_REQUEST = 'FETCH_RESERVA_TODAS_REQUEST';
export const FETCH_RESERVA_TODAS_SUCCESS = 'FETCH_RESERVA_TODAS_SUCCESS';
export const FETCH_RESERVA_TODAS_FAILURE = 'FETCH_RESERVA_TODAS_FAILURE';

export const CREATE_RESERVA_REQUEST = 'CREATE_RESERVA_REQUEST';
export const CREATE_RESERVA_SUCCESS = 'CREATE_RESERVA_SUCCESS';
export const CREATE_RESERVA_FAILURE = 'CREATE_RESERVA_FAILURE';

export const APPROVE_RESERVA_REQUEST = 'APPROVE_RESERVA_REQUEST';
export const APPROVE_RESERVA_SUCCESS = 'APPROVE_RESERVA_SUCCESS';
export const APPROVE_RESERVA_FAILURE = 'APPROVE_RESERVA_FAILURE';

export const CANCEL_RESERVA_REQUEST = 'CANCEL_RESERVA_REQUEST';
export const CANCEL_RESERVA_SUCCESS = 'CANCEL_RESERVA_SUCCESS';
export const CANCEL_RESERVA_FAILURE = 'CANCEL_RESERVA_FAILURE';
