import { all } from 'redux-saga/effects';
import { watchLogin } from './states/auth/sagas';
import { watchReserva } from './states/reserva/sagas';

export default function* rootSaga() {
    yield all([
        watchLogin(),
        watchReserva(),
    ]);
}
