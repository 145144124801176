import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome, FiInfo, FiMail, FiTerminal, FiChevronDown, FiGrid } from "react-icons/fi";
import scheduleAnimation from '../../assets/animations/schedule.json';
import Lottie from 'react-lottie';
import { useDispatch } from 'react-redux';
import { fetchReservaTodasRequest } from '../../store/states/reserva/actions';

interface SidebarProps {
  isExpanded: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(window.innerWidth >= 768);

  useEffect(() => {
    dispatch(fetchReservaTodasRequest());
  }, [dispatch]);

  const scheduleOptions = {
    loop: true,
    autoplay: true,
    animationData: scheduleAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSidebarToggle = () => {
    if (window.innerWidth >= 768) {
      toggleSidebar();
      setIsExpanded(prevState => !prevState);
    }
  };

  return (
    <div
      onClick={() => !isExpanded && handleSidebarToggle()}
      className={`fixed top-0 left-0 h-full transition-all duration-300 ease-in-out ${isExpanded ? 'w-64' : 'w-16'
        } sidebar bg-gray-800 shadow-xl flex flex-col justify-between cursor-pointer`}
    >
      <div className="flex items-center justify-between p-4 text-white">
        <div className="sidebar-logo">
          <Lottie options={scheduleOptions} height={50} width={50} />
        </div>
        <span className={`text-lg font-bold transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>
          Espaço Maljj
        </span>
        {isExpanded && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleSidebarToggle();
            }}
            className="text-white focus:outline-none transition-transform duration-200 transform hover:scale-110"
          >
            <FiChevronDown />
          </button>
        )}
      </div>
      <nav className="flex flex-col flex-grow mt-0">
        <SidebarLink
          to="/"
          icon={<FiHome />}
          label="Home"
          isExpanded={isExpanded}
          onClick={(e) => e.stopPropagation()}
          active={location.pathname === '/'}
        />
        <SidebarLink
          to="/info"
          icon={<FiInfo />}
          label="Informações"
          isExpanded={isExpanded}
          onClick={(e) => e.stopPropagation()}
          active={location.pathname === '/info'}
        />
        <SidebarLink
          to="/contact"
          icon={<FiMail />}
          label="Contato"
          isExpanded={isExpanded}
          onClick={(e) => e.stopPropagation()}
          active={location.pathname === '/contact'}
        />
        <SidebarLink
          to="/social"
          icon={<FiGrid />}
          label="Social"
          isExpanded={isExpanded}
          onClick={(e) => e.stopPropagation()}
          active={location.pathname === '/social'}
        />
        <SidebarLink
          to="/login"
          icon={<FiTerminal />}
          label="Restrito"
          isExpanded={isExpanded}
          onClick={(e) => e.stopPropagation()}
          active={location.pathname === '/settings'}
        />
      </nav>
      <div className="p-4 text-gray-400 text-sm text-center">
        {isExpanded && <span>&copy; {new Date().getFullYear()} Espaço Maljj</span>}
      </div>
    </div>
  );
};

interface SidebarLinkProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  isExpanded: boolean;
  onClick: (e: React.MouseEvent) => void;
  active: boolean;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ to, icon, label, isExpanded, onClick, active }) => (
  <Link
    to={to}
    onClick={onClick}
    className={`flex items-center p-4 text-gray-300 hover:bg-gray-700 rounded-lg transition duration-200 hover:shadow-lg ${active ? 'bg-gray-500 transform scale-55 translate-x-1' : ''}`}
  >
    <span className="mr-4 text-xl">{icon}</span>
    {isExpanded && <span className="font-medium">{label}</span>}
  </Link>
);

export default Sidebar;
