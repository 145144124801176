import React, { useState } from 'react';
import calendarAnimation from '../../assets/animations/calendar.json';
import CalendarComponent from '../../components/CalendarComponent/CalendarComponent';
import Lottie from 'react-lottie';
import './HomePage.css';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: calendarAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
    speed: 2
  };

  return (
    <div className="futuristic-container animate-slide-in">
      <h1 className="futuristic-title">Espaço Maljj</h1>
      <p className="futuristic-subtitle">Faça sua pré-reserva de forma rápida e fácil.</p>
      <div className="home-container">
        <Lottie options={defaultOptions} height={150} width={150} />
        <CalendarComponent selectedDate={selectedDate} onChange={setSelectedDate} />
      </div>
      <Link to="/info">
        <button className="futuristic-button">Saiba Mais</button>
      </Link>
    </div>
  );
};

export default HomePage;
