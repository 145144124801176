import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FilterComponent.css';

interface FilterComponentProps {
    filter: string;
    setFilter: (filter: string) => void;
    startDate: Date | undefined;
    setStartDate: (date: Date | undefined) => void;
    endDate: Date | undefined;
    setEndDate: (date: Date | undefined) => void;
    showFutureOnly: boolean;
    setShowFutureOnly: (showFutureOnly: boolean) => void;
    showConfirmed: boolean;
    setShowConfirmed: (showConfirmed: boolean) => void;
    showPending: boolean;
    setShowPending: (showPending: boolean) => void;
    showPast: boolean;
    setShowPast: (showPast: boolean) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
    filter,
    setFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    showFutureOnly,
    setShowFutureOnly,
    showConfirmed,
    setShowConfirmed,
    showPending,
    setShowPending,
    showPast,
    setShowPast }) => {
    return (
        <div className="filters">
            <div className="date-picker-container">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date || undefined)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Data Inicial"
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                />
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date || undefined)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Data Final"
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <input
                type="text"
                className="search-input"
                placeholder="Filtrar por nome ou status..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />
            <div className="filters">
                <div className="filter-group">
                    <label className="filter-label">
                        <input className='input-checkbox' type="checkbox" checked={showFutureOnly} onChange={(e) => setShowFutureOnly(e.target.checked)} />
                        Futuras
                    </label>
                    <label className="filter-label">
                        <input className='input-checkbox' type="checkbox" checked={showConfirmed} onChange={(e) => setShowConfirmed(e.target.checked)} />
                        Confirmadas
                    </label>
                    <label className="filter-label">
                        <input className='input-checkbox' type="checkbox" checked={showPending} onChange={(e) => setShowPending(e.target.checked)} />
                        Pendentes
                    </label>
                    <label className="filter-label">
                        <input className='input-checkbox' type="checkbox" checked={showPast} onChange={(e) => setShowPast(e.target.checked)} />
                        Passadas
                    </label>
                </div>
            </div>
        </div>
    );
};

export default FilterComponent;
