import { takeLatest, call, put } from 'redux-saga/effects';
import { loginError, setAccessToken } from './actions';
import { authApi } from '../../api/endpoints/authApi';
import { LOGIN } from './types';

export function* loginSaga(action: any) {
  try {
    const { email, password, history } = action.payload;
    const { access_token, user } = yield call(authApi, email, password);
    if (access_token) {
      yield put(setAccessToken(access_token, user));
      history.push('/admin');
    } else {
      console.log('Falha ao validar o token!');
    }
  } catch (error: any) {
    yield put(loginError());
    if (error.code === 'ERR_BAD_REQUEST') {
      console.log('Usuário e/ou senha incorreto(s)!');
    } else if (error.code === 'ERR_NETWORK') {
      console.log('Falha ao realizar a conexão com servidor. Tente novamente mais tarde...');
    } else {
      console.log('Erro de requisição' + error);
    }
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN, loginSaga);
}
