import { combineReducers } from 'redux';
import { reducer as authReducer } from './states/auth/reducers';
import { reducer as reservaReducer } from './states/reserva/reducers';
import { reducer as messageReducer } from './states/message/reducers';

const rootReducer = combineReducers({
    auth: authReducer,
    reserva: reservaReducer,
    message: messageReducer
});
export default rootReducer;
