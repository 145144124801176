import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { unparse } from 'papaparse';

interface Reservation {
    reservaId: string;
    nome: string;
    dataSelecionada: string;
    aprovado: boolean;
    documento: string;
    telefone: string;
    endereco?: string;
    dataCadastro: string;
}

interface Reservation {
    reservaId: string;
    nome: string;
    dataSelecionada: string;
    aprovado: boolean;
    documento: string;
    telefone: string;
    endereco?: string;
    dataCadastro: string;
}

export const exportToPDF = (reservations: Reservation[], singleReservation?: Reservation) => {
    const doc = new jsPDF();
    const pageContent = function (data: any) {
        doc.setFontSize(18);
        doc.setTextColor(40);
        if (data.pageCount === 1) {
            doc.text("Relatório de Reservas", data.settings.margin.left, 22);
        }
        let str = "Página " + data.pageNumber;
        doc.setFontSize(10);
        doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    const columns = [
        { header: 'Nome', dataKey: 'nome' },
        { header: 'Data Selecionada', dataKey: 'dataSelecionada' },
        { header: 'Status', dataKey: 'aprovado', formatter: (value: boolean) => value ? 'Confirmada' : 'Pendente' },
        { header: 'Documento', dataKey: 'documento' },
        { header: 'Telefone', dataKey: 'telefone' },
        { header: 'Endereço', dataKey: 'endereco' },
        { header: 'Data de Cadastro', dataKey: 'dataCadastro' }
    ];

    const dataToExport = (singleReservation ? [singleReservation] : reservations).map(reservation => ({
        nome: reservation.nome,
        dataSelecionada: moment(reservation.dataSelecionada, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        aprovado: reservation.aprovado ? 'Confirmada' : 'Pendente',
        documento: reservation.documento,
        telefone: reservation.telefone,
        endereco: reservation.endereco || '',
        dataCadastro: reservation.dataCadastro
    }));

    autoTable(doc, {
        columns: columns,
        body: dataToExport,
        didDrawPage: pageContent,
        margin: { top: 30 },
        theme: 'grid',
        headStyles: { fillColor: [41, 128, 185] },
        alternateRowStyles: { fillColor: [240, 240, 240] },
        styles: { font: "helvetica", fontSize: 11 }
    });
    doc.save('reservas.pdf');
};

export const exportToCSV = (reservations: Reservation[], singleReservation?: Reservation) => {
    const dataToExport = singleReservation ? [singleReservation] : reservations;
    const filteredData = dataToExport.map(res => ({
        nome: res.nome,
        dataSelecionada: moment(res.dataSelecionada, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        documento: res.documento,
        telefone: res.telefone,
        endereco: res.endereco,
        aprovado: res.aprovado ? 'Confirmada' : 'Pendente',
        dataCadastro: moment(res.dataCadastro, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
    }));
    const csv = unparse(filteredData, {
        delimiter: ","
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "reservas.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
