// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100%;
    animation: fadeIn 1s;
}

.total-reservations,
.total-confirmed,
.total-pending {
    text-align: center;
    font-size: 16px;
    padding: 10px;
    background: linear-gradient(145deg, #354b6b, #1f2937);
    border: 1px solid #ddd;
    color: #ffffff !important;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .statistics {
        flex-direction: column;
        align-items: center;
    }

    .total-reservations,
    .total-confirmed,
    .total-pending {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/StatsPanel/StatsPanel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;IACX,oBAAoB;AACxB;;AAEA;;;IAGI,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,qDAAqD;IACrD,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;;;QAGI,WAAW;IACf;AACJ","sourcesContent":[".statistics {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 20px;\n    width: 100%;\n    animation: fadeIn 1s;\n}\n\n.total-reservations,\n.total-confirmed,\n.total-pending {\n    text-align: center;\n    font-size: 16px;\n    padding: 10px;\n    background: linear-gradient(145deg, #354b6b, #1f2937);\n    border: 1px solid #ddd;\n    color: #ffffff !important;\n    border-radius: 4px;\n}\n\n@media (max-width: 768px) {\n    .statistics {\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .total-reservations,\n    .total-confirmed,\n    .total-pending {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
