import React, { useEffect, useRef, useState } from 'react';
import { FiEye, FiEyeOff } from "react-icons/fi";
import moment from 'moment';
import { exportToCSV, exportToPDF } from '../../utils/exportUtils';
import 'react-datepicker/dist/react-datepicker.css';
import './ReservationsTable.css';
import FilterComponent from '../FilterComponent/FilterComponent';
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal';

interface Reservation {
    reservaId: string;
    nome: string;
    dataSelecionada: string;
    aprovado: boolean;
    documento: string;
    telefone: string;
    endereco?: string;
    dataCadastro: string;
}

interface ReservationsTableProps {
    reservations: Reservation[];
    onStatusChange: (id: string, newStatus: 'approve' | 'cancel') => void;
}

const ReservationsTable: React.FC<ReservationsTableProps> = ({ reservations, onStatusChange }) => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [currentId, setCurrentId] = useState<string | null>(null);
    const [actionType, setActionType] = useState<'approve' | 'cancel' | null>(null);
    const [filter, setFilter] = useState('');
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(0);
    const [expandedId, setExpandedId] = useState<string | null>(null);
    const [showFutureOnly, setShowFutureOnly] = useState(false);
    const [showConfirmed, setShowConfirmed] = useState(false);
    const [showPending, setShowPending] = useState(false);
    const [showPast, setShowPast] = useState(false);
    const [filteredReservations, setFilteredReservations] = useState<Reservation[]>([]);
    const filteredRef = useRef(filteredReservations);
    const pageSize = 5;

    useEffect(() => {
        filteredRef.current = filteredReservations;
    }, [filteredReservations]);

    useEffect(() => {
        const filtered = reservations.length ? reservations.filter(reservation => {
            const reservationDate = moment(reservation.dataSelecionada, 'YYYY-MM-DD');
            const pastCondition = showPast ? reservationDate.isBefore(moment().startOf('day')) : true;
            return pastCondition &&
                (!startDate || reservationDate.isSameOrAfter(moment(startDate))) &&
                (!endDate || reservationDate.isSameOrBefore(moment(endDate))) &&
                (showFutureOnly ? reservationDate.isSameOrAfter(moment().startOf('day')) : true) &&
                (showConfirmed ? reservation.aprovado : true) &&
                (showPending ? !reservation.aprovado : true) &&
                (reservation.nome.toLowerCase().includes(filter.toLowerCase()) ||
                    reservation.aprovado.toString().toLowerCase().includes(filter.toLowerCase()));
        }) : [];
        setFilteredReservations(filtered);
    }, [reservations, startDate, endDate, filter, showFutureOnly, showConfirmed, showPending, showPast]);

    const paginatedReservations = filteredReservations.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    const toggleDetails = (reservaId: string) => {
        setExpandedId(expandedId === reservaId ? null : reservaId);
    };

    const isPastDate = (date: string) => {
        return moment(date, 'YYYY-MM-DD').isBefore(moment().startOf('day'));
    };

    const handleExportPDF = () => {
        exportToPDF(filteredRef.current);
    };

    const handleExportCSV = () => {
        exportToCSV(filteredRef.current);
    };

    const handleStatusChange = (id: string, newStatus: 'approve' | 'cancel') => {
        setCurrentId(id);
        setActionType(newStatus);
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setCurrentId(null);
        setActionType(null);
    };

    const confirmChange = () => {
        if (currentId && actionType) {
            onStatusChange(currentId, actionType);
        }
        closeConfirmModal();
    };

    return (
        <div className="table-container">
            <FilterComponent
                filter={filter}
                setFilter={setFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                showFutureOnly={showFutureOnly}
                setShowFutureOnly={setShowFutureOnly}
                showConfirmed={showConfirmed}
                setShowConfirmed={setShowConfirmed}
                showPending={showPending}
                setShowPending={setShowPending}
                showPast={showPast}
                setShowPast={setShowPast}
            />
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>Detalhes</th>
                        <th>Nome</th>
                        <th>Data Selecionada</th>
                        <th>Status</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedReservations.map(reservation => (
                        <>
                            <tr key={reservation.reservaId}>
                                <td>
                                    <button onClick={() => toggleDetails(reservation.reservaId)}>
                                        {expandedId === reservation.reservaId ? <FiEyeOff /> : <FiEye />}
                                    </button>
                                </td>
                                <td>{reservation.nome}</td>
                                <td>{moment(reservation.dataSelecionada).format('DD/MM/YYYY')}</td>
                                <td>{reservation.aprovado ? 'Confirmada' : 'Pendente'}</td>
                                <td>
                                    <button
                                        className={`status-button ${reservation.aprovado ? 'pending' : 'confirm'}`}
                                        onClick={() => handleStatusChange(reservation.reservaId, reservation.aprovado ? 'cancel' : 'approve')}
                                        disabled={isPastDate(reservation.dataSelecionada)}>
                                        {reservation.aprovado ? 'Cancelar Reserva' : 'Confirmar Reserva'}
                                    </button>
                                </td>
                            </tr>
                            {expandedId === reservation.reservaId && (
                                <tr>
                                    <td colSpan={5}>
                                        <div className="reservation-details">
                                            <p>Documento: {reservation.documento}</p>
                                            <p>Telefone: {reservation.telefone}</p>
                                            <p>Endereço: {reservation.endereco || 'Não fornecido'}</p>
                                            <p>Data de Cadastro: {reservation.dataCadastro}</p>
                                            <button onClick={handleExportPDF} className="export-button">Exportar esta reserva (PDF)</button>
                                            <button onClick={handleExportCSV} className="export-button">Exportar esta reserva (CSV)</button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button className='futuristic-button' onClick={() => setCurrentPage(p => p > 0 ? p - 1 : p)} disabled={currentPage === 0}>Anterior</button>
                <span className='futuristic-text'>Página {currentPage + 1} de {Math.ceil(filteredReservations.length / pageSize)}</span>
                <button className='futuristic-button' onClick={() => setCurrentPage(p => p < Math.ceil(filteredReservations.length / pageSize) - 1 ? p + 1 : p)} disabled={currentPage >= Math.ceil(filteredReservations.length / pageSize) - 1}>Próximo</button>
            </div>
            <div className='export-buttons'>
                <button className='futuristic-button' onClick={() => exportToPDF(filteredReservations)}>Exportar PDF</button>
                <button className='futuristic-button' onClick={() => exportToCSV(filteredReservations)}>Exportar CSV</button>
            </div>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onConfirm={confirmChange}
                onCancel={closeConfirmModal}
                message={`Você tem certeza que deseja ${actionType === 'approve' ? 'confirmar' : 'cancelar'} esta reserva?`}
            />
        </div>
    );
};

export default ReservationsTable;
