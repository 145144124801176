import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import Lottie from 'react-lottie';
import smarfAnimation from '../../assets/animations/smarf.json';

const Footer: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: smarfAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const whatsappUrl = `https://wa.me/5531980105687?text=${encodeURIComponent("Olá, gostaria de saber mais sobre essa solução da Smarf Technology")}`;

  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <h2 className="text-2xl font-bold text-center">Espaço Maljj</h2>
          </div>
          <div className="flex space-x-4 mt-4 md:mt-0 md:justify-start">
            <a href="https://www.facebook.com/espaco_maljj/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-200">
              <FaFacebook size={24} />
            </a>
            <a href="https://www.instagram.com/espaco_maljj/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-200">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.twitter.com/espaco_maljj/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-200">
              <FaTwitter size={24} />
            </a>
          </div>
        </div>
        <div className="mt-6 text-center">
          <div className="inline-block mr-2">
            
          </div>
          <span className="text-gray-400">
            &copy; {new Date().getFullYear()} <a href={whatsappUrl} className="hover:text-white transition duration-200" target="_blank" rel="noopener noreferrer">Smarf Technology</a>. Todos os direitos reservados.
          </span>
          <Lottie options={defaultOptions} height={75} width={75} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
