import React, { useState } from 'react';
import './ContactPage.css';
import EmailModal from '../../components/Modals/EmailModal/EmailModal';
import Lottie from 'react-lottie';
import contactAnimation from '../../assets/animations/location.json';
import { FaMapMarkerAlt, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const ContactPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: contactAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const whatsappUrl = `https://wa.me/5531994948900?text=${encodeURIComponent("Olá, gostaria de saber mais sobre reservas no Espaço Maljj")}`;

  return (
    <div className="futuristic-container animate-slide-in">
      <h1 className="futuristic-title">Entre em Contato</h1>
      <Lottie options={defaultOptions} height={150} width={150} />
      <p className="contact-description">
        Estamos aqui para ajudar! Entre em contato conosco ou venha nos fazer uma visita.
      </p>
      <button className="futuristic-button" onClick={openModal}>
        Enviar Mensagem
      </button>
      <div className="map-container">
        <iframe
          title="Mapa do Espaço Maljj"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3754.3941640861457!2d-44.0111682850926!3d-19.792803486673007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa694b5f81f107b%3A0x770e65f0f1d83b8b!2sR.%20P%C3%A3o%20de%20A%C3%A7%C3%BAcar%2C%2070%20-%20Botafogo%20(Justin%C3%B3polis)%2C%20Ribeir%C3%A3o%20das%20Neves%20-%20MG%2C%2033902-320!5e0!3m2!1spt-BR!2sbr!4v1691440502058!5m2!1spt-BR!2sbr"
          width="100%"
          height="450"
          style={{ border: 0, borderRadius: '1rem' }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
      <div className="contact-info">
        <h2>Informações de Contato</h2>
        <div className="contact-item">
          <FaWhatsapp className="contact-icon" />
          <p><a href={whatsappUrl} className="hover:text-white transition duration-200" target="_blank" rel="noopener noreferrer">Clique aqui para falar conosco</a></p>
        </div>
        <div className="contact-item">
          <FaEnvelope className="contact-icon" />
          <p>espacomaljj1@gmail.com</p>
        </div>
        <div className="contact-item">
          <FaMapMarkerAlt className="contact-icon" />
          <p>R. Pão de Açúcar, 70 - Botafogo</p>
        </div>
        <div className="contact-item">
          <p>Justinópolis, Ribeirão das Neves - MG</p>
        </div>
        <div className="contact-item">
          <p>33902-320</p>
        </div>
      </div>
      <EmailModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ContactPage;
