import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: baseUrl,
});

let authToken: null = null;

export const fetchToken = async () => {
  try {
    const domain = window.location.hostname;
    const response = await api.get('/get-token', { headers: { 'X-Custom-Domain': domain } });
    console.log('Token obtido');
    authToken = response.data.access_token
    setAxiosInterceptors();
  } catch (error) {
    console.error('Erro ao obter o token:', error);
  }
};

const setAxiosInterceptors = () => {
  api.interceptors.request.use(
    config => {
      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.response && error.response.status === 401) {
        console.log('Erro de autenticação:', error);
        await fetchToken();
      }
      return Promise.reject(error);
    },
  );
};

fetchToken();

export default api;
